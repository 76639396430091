import React from "react";
import theme from "theme";
import { Theme, Text, Box, Link, Em, Span, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override, StackItem, Stack, SocialMedia, Menu } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Games from Ternox
			</title>
			<meta name={"description"} content={"Indie games developer and publisher."} />
			<meta property={"og:title"} content={"Games from Ternox"} />
			<meta property={"og:description"} content={"Indie games developer and publisher."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/fon.jpg?v=2020-10-03T22:47:21.056Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/himmm.png?v=2020-10-03T22:34:30.191Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/himmm.png?v=2020-10-03T22:34:30.191Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/himmm.png?v=2020-10-03T22:34:30.191Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/himmm.png?v=2020-10-03T22:34:30.191Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/himmm.png?v=2020-10-03T22:34:30.191Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/himmm.png?v=2020-10-03T22:34:30.191Z"} />
		</Helmet>
		<Components.Menuu />
		<Section
			padding="0 0 0 0"
			sm-padding="40px 0"
			font="--base"
			color="--darkL1"
			background="--color-light"
			margin="0 0 0 0"
		>
			<Override slot="SectionContent" max-width="1400px" background="--color-light" />
			<Text
				text-align="center"
				as="h1"
				font="--headline1"
				md-font="--headline2"
				max-width="1020px"
				margin="0 auto 5px auto"
			>
				I am 
developing 

games
			</Text>
			<Text
				as="p"
				color="--grey"
				max-width="520px"
				margin="0 auto"
				text-align="center"
				font="--lead"
			>
				And I like it. Below is a list of games that I made myself.
			</Text>
			<Stack margin-top="40px">
				<StackItem
					margin="0px 0px 25px 0px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
					sm-margin-top="24px"
				>
					<Override slot="StackItemContent" flex-direction="column" />
					<Box
						background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/vert3.png?v=2024-11-27T19:14:33.514Z) 50% 0/cover no-repeat"
						transition="all 0.3s ease 0s"
						box-shadow="3px 3px 7px 1px rgba(0, 0, 0, 0.46)"
						hover-transition="all 0.3s --transitionTimingFunction-easeIn 0.0s"
						hover-transform="scale(1.15)"
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
					/>
					<Text color="--grey" margin="0">
						Pocket Dating Simulator{"\n\n"}
					</Text>
					<Text margin="5px 0 20px 0" as="h3" font="--headline3">
						PockeDate!
					</Text>
					<Text margin="5px 0 5px 0" as="p">
						Your Pocket-Sized Dating Simulator! Every launch brings a new date with unique dialogues! Spend endless hours with your adorable, love-struck virtual girlfriend.
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						{"\n\n"}
						<br />
						{"\n\n"}
					</Text>
					<Box
						position="static"
						as="div"
						text-align="center"
						margin="auto 0px 0px 0px"
						display="block"
					>
						<Link
							href="https://store.steampowered.com/app/3107350"
							padding="7px 20px 7px 20px"
							color="#F7FBFF"
							border-radius="2px"
							hover-background="--color-darkL1"
							target="_blank"
							background="--color-primary"
							margin="3px 3px 3px 3px"
							text-decoration-line="initial"
							display="inline-block"
						>
							PC (Steam)
						</Link>
					</Box>
				</StackItem>
				<StackItem
					margin="0px 0px 25px 0px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
					sm-margin-top="24px"
				>
					<Override slot="StackItemContent" flex-direction="column" />
					<Box
						background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/kvst.png?v=2022-01-08T11:10:01.503Z) 50% 0/cover no-repeat"
						transition="all 0.3s ease 0s"
						box-shadow="3px 3px 7px 1px rgba(0, 0, 0, 0.46)"
						hover-transition="all 0.3s --transitionTimingFunction-easeIn 0.0s"
						hover-transform="scale(1.15)"
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
					/>
					<Text color="--grey" margin="0">
						Early Access
					</Text>
					<Text margin="5px 0 20px 0" as="h3" font="--headline3">
						STONKS-9800: Stock Market Simulator
					</Text>
					<Text margin="5px 0 5px 0" as="p">
						Simulator of an 80s Japanese stock market businessman. Chill, catch a retro vibe and watch your profits grow in the text-based game STONKS-9800.
						<br />
						{"\n\n"}
					</Text>
					<Box
						position="static"
						as="div"
						text-align="center"
						margin="auto 0px 0px 0px"
						display="block"
					>
						<Link
							href="https://store.steampowered.com/app/1539140/STONKS9800_Stock_Market_Simulator"
							padding="7px 20px 7px 20px"
							color="#F7FBFF"
							border-radius="2px"
							hover-background="--color-darkL1"
							target="_blank"
							background="--color-primary"
							margin="3px 3px 3px 3px"
							text-decoration-line="initial"
							display="inline-block"
						>
							PC (Steam)
						</Link>
						<Link
							href="https://ternoxgames.com/stonks9800"
							padding="7px 20px 7px 20px"
							color="#F7FBFF"
							border-radius="2px"
							hover-background="--color-darkL1"
							target="_self"
							background="--color-primary"
							margin="3px 3px 3px 3px"
							text-decoration-line="initial"
							display="inline-block"
						>
							Press Kit
						</Link>
					</Box>
				</StackItem>
				<StackItem
					margin="0px 0px 25px 0px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
					sm-margin-top="24px"
				>
					<Override slot="StackItemContent" flex-direction="column" />
					<Box
						background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/SQ_NSwitchDS_TaimumariCompleteEdition.jpg?v=2020-10-04T13:53:02.389Z) 50% 0/cover no-repeat"
						transition="all 0.3s ease 0s"
						box-shadow="3px 3px 7px 1px rgba(0, 0, 0, 0.46)"
						hover-transition="all 0.3s --transitionTimingFunction-easeIn 0.0s"
						hover-transform="scale(1.15)"
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
					/>
					<Text color="--grey" margin="0">
						Platformer inspired by Mega Man X
					</Text>
					<Text margin="5px 0 20px 0" as="h3" font="--headline3">
						Taimumari
					</Text>
					<Text margin="5px 0 5px 0" as="p">
						Retro-style platform game in keeping with the best traditions of games of our childhood! Take control of the Himari, the young female wizard travelling across various ages to settle the balance in time across the whole world!
						<br />
					</Text>
					<Box
						position="static"
						as="div"
						text-align="center"
						margin="auto 0px 0px 0px"
						display="block"
					>
						<Link
							href="http://store.steampowered.com/app/375520"
							padding="7px 20px 7px 20px"
							color="#F7FBFF"
							border-radius="2px"
							hover-background="--color-darkL1"
							target="_blank"
							background="--color-primary"
							margin="3px 3px 3px 3px"
							text-decoration-line="initial"
							display="inline-block"
						>
							PC (Steam)
						</Link>
						<Link
							href="https://www.microsoft.com/en-us/p/taimumari-complete-edition/9nt9qtftj8bk"
							margin="3px 3px 3px 3px"
							color="#F7FBFF"
							text-decoration-line="initial"
							border-radius="2px"
							background="--color-primary"
							target="_blank"
							padding="7px 20px 7px 20px"
							display="inline-block"
							hover-background="--color-darkL1"
						>
							Xbox
						</Link>
						<Link
							href="https://www.nintendo.com/games/detail/taimumari-complete-edition-switch/"
							background="--color-primary"
							target="_blank"
							margin="3px 3px 3px 3px"
							display="inline-block"
							hover-background="--color-darkL1"
							padding="7px 20px 7px 20px"
							color="#F7FBFF"
							text-decoration-line="initial"
							border-radius="2px"
						>
							Nintendo Switch
						</Link>
						<Link
							background="--color-primary"
							target="_blank"
							margin="3px 3px 3px 3px"
							color="#F7FBFF"
							display="inline-block"
							href="https://store.playstation.com/en-us/product/UP5817-CUSA19019_00-TAIMUMARI00000US"
							padding="7px 20px 7px 20px"
							text-decoration-line="initial"
							border-radius="2px"
							hover-background="--color-darkL1"
						>
							Playstation 4
						</Link>
					</Box>
				</StackItem>
				<StackItem margin="0px 0px 25px 0px" width="25%" lg-width="50%" sm-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Box
						hover-transition="all 0.3s --transitionTimingFunction-easeIn 0.0s"
						transition="all 0.3s ease 0s"
						box-shadow="3px 3px 7px 1px rgba(0, 0, 0, 0.46)"
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/nexoria-min.jpg?v=2020-10-03T23:32:11.660Z) 50% 0/cover no-repeat"
						hover-transform="scale(1.15)"
					/>
					<Text color="--grey" margin="0">
						Rogue-like with cards
					</Text>
					<Text margin="5px 0 20px 0" as="h3" font="--headline3">
						Nexoria: Dungeon Rogue Heroes
					</Text>
					<Text as="p" margin="5px 0 5px 0">
						Hardcore roguelike about dungeons with hybrid battles, combining classical turn based combat system and a collectible card game. Collect the best spells and conquer the dungeons!
					</Text>
					<Box
						display="block"
						position="static"
						as="div"
						text-align="center"
						margin="auto 0px 0px 0px"
					>
						<Components.Knopa href="https://store.steampowered.com/app/932210/Nexoria_Dungeon_Rogue_Heroes/" />
						<Link
							background="--color-primary"
							text-decoration-line="initial"
							display="inline-block"
							hover-background="--color-darkL1"
							href="https://www.microsoft.com/en-us/p/nexoria-dungeon-rogue-heroes/9nxmdckxdwbp"
							padding="7px 20px 7px 20px"
							margin="3px 3px 3px 3px"
							color="#F7FBFF"
							border-radius="2px"
							target="_blank"
						>
							Xbox
						</Link>
						<Link
							text-decoration-line="initial"
							border-radius="2px"
							display="inline-block"
							href="https://www.nintendo.com/games/detail/nexoria-dungeon-rogue-heroes-switch/"
							background="--color-primary"
							padding="7px 20px 7px 20px"
							hover-background="--color-darkL1"
							target="_blank"
							margin="3px 3px 3px 3px"
							color="#F7FBFF"
						>
							Nintendo Switch™
						</Link>
						<Link
							padding="7px 20px 7px 20px"
							text-decoration-line="initial"
							hover-background="--color-darkL1"
							background="--color-primary"
							target="_blank"
							color="#F7FBFF"
							border-radius="2px"
							display="inline-block"
							href="https://store.playstation.com/en-us/product/UP4892-CUSA29630_00-8934677098980870/"
							margin="3px 3px 3px 3px"
						>
							Playstation 4, 5
						</Link>
					</Box>
					{" "}
				</StackItem>
				<StackItem lg-width="50%" sm-width="100%" margin="0px 0px 25px 0px" width="25%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Box
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/fingerfff.jpg?v=2021-11-14T15:04:14.573Z) 50% 0/cover no-repeat"
						hover-transform="scale(1.15)"
						hover-transition="all 0.3s --transitionTimingFunction-easeIn 0.0s"
						transition="all 0.3s ease 0s"
						box-shadow="3px 3px 7px 1px rgba(0, 0, 0, 0.46)"
					/>
					<Text color="--grey" margin="0">
						Next-gen 
Incremental game
					</Text>
					<Text as="h3" font="--headline3" margin="5px 0 20px 0">
						Finger Fitness
					</Text>
					<Text as="p" margin="5px 0 5px 0">
						Finger Fitness is idle game with all Dualsense features! Click on triggers to get points. Upgrade your abilities, buy pets that will bring score and stretch your index fingers!
					</Text>
					<Box
						display="block"
						position="static"
						as="div"
						text-align="center"
						margin="auto 0px 0px 0px"
					>
						<Link
							text-decoration-line="initial"
							border-radius="2px"
							display="inline-block"
							hover-background="--color-darkL1"
							href="https://store.playstation.com/en-us/concept/10003828"
							background="--color-primary"
							padding="7px 20px 7px 20px"
							margin="3px 3px 3px 3px"
							target="_blank"
							color="#F7FBFF"
						>
							Playstation 5
						</Link>
					</Box>
					{" "}
				</StackItem>
				<StackItem
					width="25%"
					lg-width="50%"
					sm-width="100%"
					sm-margin-top="24px"
					margin="0px 0px 25px 0px"
				>
					<Override slot="StackItemContent" flex-direction="column" />
					<Box
						hover-transition="all 0.3s --transitionTimingFunction-easeIn 0.0s"
						hover-transform="scale(1.15)"
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/legend.jpg?v=2020-10-03T23:32:27.115Z) 50% 0/cover no-repeat"
						transition="all 0.3s ease 0s"
						box-shadow="3px 3px 7px 1px rgba(0, 0, 0, 0.46)"
					/>
					<Text color="--grey" margin="0">
						Arcade
					</Text>
					<Text as="h3" font="--headline3" margin="5px 0 20px 0">
						Legend of Himari
					</Text>
					<Text margin="5px 0 5px 0" as="p">
						Himari was ambushed! Help her hold out as long as possible, attack enemies, use magic to slay them, maintain Himari’s health level, so she could fight on, and also do not miss!
						<br />
						<br />
						<Em
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
						>
							Exist on consoles as part of Taimumari: Complete Edition
						</Em>
					</Text>
					<Box
						display="block"
						position="static"
						as="div"
						text-align="center"
						margin="auto 0px 0px 0px"
					>
						<Link
							border-radius="2px"
							display="inline-block"
							color="#F7FBFF"
							target="_blank"
							background="--color-primary"
							padding="7px 20px 7px 20px"
							margin="3px 3px 3px 3px"
							text-decoration-line="initial"
							hover-background="--color-darkL1"
							href="http://store.steampowered.com/app/634340/Legend_of_Himari/"
						>
							PC (Steam)
						</Link>
						<Link
							text-decoration-line="initial"
							border-radius="2px"
							display="inline-block"
							color="#F7FBFF"
							hover-background="--color-darkL1"
							href="https://play.google.com/store/apps/details?id=com.companyname.Legend_of_Himari&hl=en"
							background="--color-primary"
							target="_blank"
							padding="7px 20px 7px 20px"
							margin="3px 3px 3px 3px"
						>
							Play Market
						</Link>
					</Box>
				</StackItem>
				<StackItem
					margin="0px 0px 25px 0px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
					sm-margin-top="24px"
				>
					<Override slot="StackItemContent" flex-direction="column" />
					<Box
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/SQ_NSwitchDS_InVert.jpg?v=2020-10-04T13:53:17.340Z) 50% 0/cover no-repeat"
						transition="all 0.3s ease 0s"
						box-shadow="3px 3px 7px 1px rgba(0, 0, 0, 0.46)"
						hover-transition="all 0.3s --transitionTimingFunction-easeIn 0.0s"
						hover-transform="scale(1.15)"
					/>
					<Text color="--grey" margin="0">
						Puzzle platformer
					</Text>
					<Text as="h3" font="--headline3" margin="5px 0 20px 0">
						IN-VERT
					</Text>
					<Text as="p" margin="5px 0 5px 0">
						This is a short hardcore enthralling puzzle-platform game about a lonely robot, which tries to find his master. To go all the way you'll have to look for the master in two dimensions at the same time. Parallel world module will help to overcome any obstacles!
						<br />
					</Text>
					<Box
						display="block"
						position="static"
						as="div"
						text-align="center"
						margin="auto 0px 0px 0px"
					>
						<Link
							target="_blank"
							padding="7px 20px 7px 20px"
							margin="3px 3px 3px 3px"
							border-radius="2px"
							hover-background="--color-darkL1"
							href="http://store.steampowered.com/app/491010/INVERT/"
							background="--color-primary"
							color="#F7FBFF"
							text-decoration-line="initial"
							display="inline-block"
						>
							PC (Steam)
						</Link>
						<Link
							display="inline-block"
							background="--color-primary"
							padding="7px 20px 7px 20px"
							border-radius="2px"
							color="#F7FBFF"
							text-decoration-line="initial"
							hover-background="--color-darkL1"
							href="https://www.microsoft.com/en-us/p/in-vert/9mt9l8jh590n"
							target="_blank"
							margin="3px 3px 3px 3px"
						>
							Xbox
						</Link>
						<Link
							href="https://www.nintendo.com/games/detail/in-vert-switch/"
							target="_blank"
							padding="7px 20px 7px 20px"
							margin="3px 3px 3px 3px"
							text-decoration-line="initial"
							display="inline-block"
							hover-background="--color-darkL1"
							background="--color-primary"
							color="#F7FBFF"
							border-radius="2px"
						>
							Nintendo Switch
						</Link>
						<Link
							display="inline-block"
							href="https://store.playstation.com/en-us/product/EP8009-CUSA17824_00-INVERT00000000EU"
							background="--color-primary"
							padding="7px 20px 7px 20px"
							text-decoration-line="initial"
							border-radius="2px"
							hover-background="--color-darkL1"
							target="_blank"
							margin="3px 3px 3px 3px"
							color="#F7FBFF"
						>
							Playstation 4
						</Link>
					</Box>
				</StackItem>
				<StackItem
					lg-width="50%"
					sm-width="100%"
					sm-margin-top="24px"
					margin="0px 0px 25px 0px"
					width="25%"
				>
					<Override slot="StackItemContent" flex-direction="column" />
					<Box
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/bullet_big.jpg?v=2020-10-03T23:32:50.124Z) 50% 0/cover no-repeat"
						transition="all 0.3s ease 0s"
						box-shadow="3px 3px 7px 1px rgba(0, 0, 0, 0.46)"
						hover-transition="all 0.3s --transitionTimingFunction-easeIn 0.0s"
						hover-transform="scale(1.15)"
						height="0"
					/>
					<Text margin="0" color="--grey">
						Shoot'em'up with rythm
					</Text>
					<Text margin="5px 0 20px 0" as="h3" font="--headline3">
						Bullet Beat
					</Text>
					<Text as="p" margin="5px 0 5px 0">
						Bullet Beat is a shoot'em up, where everything at the levels is in tune with the music, even shooting!
						<br />
						<br />
						Keep the combo to get stronger!
						<br />
					</Text>
					<Box
						margin="auto 0px 0px 0px"
						display="block"
						position="static"
						as="div"
						text-align="center"
					>
						<Link
							href="https://store.steampowered.com/app/1180620/Bullet_Beat/"
							background="--color-primary"
							margin="3px 3px 3px 3px"
							text-decoration-line="initial"
							border-radius="2px"
							hover-background="--color-darkL1"
							target="_blank"
							padding="7px 20px 7px 20px"
							color="#F7FBFF"
							display="inline-block"
						>
							PC (Steam)
						</Link>
						<Link
							display="inline-block"
							hover-background="--color-darkL1"
							background="--color-primary"
							padding="7px 20px 7px 20px"
							margin="3px 3px 3px 3px"
							color="#F7FBFF"
							border-radius="2px"
							href="https://www.microsoft.com/en-us/p/bullet-beat/9p76wskflz12"
							target="_blank"
							text-decoration-line="initial"
						>
							Xbox
						</Link>
						<Link
							target="_blank"
							color="#F7FBFF"
							text-decoration-line="initial"
							border-radius="2px"
							display="inline-block"
							hover-background="--color-darkL1"
							href="https://www.nintendo.co.uk/Games/Nintendo-Switch-download-software/Bullet-Beat-1850285.html"
							background="--color-primary"
							padding="7px 20px 7px 20px"
							margin="3px 3px 3px 3px"
						>
							Nintendo Switch
						</Link>
					</Box>
				</StackItem>
				<StackItem
					lg-width="50%"
					sm-width="100%"
					sm-margin-top="24px"
					margin="0px 0px 25px 0px"
					width="25%"
				>
					<Override slot="StackItemContent" flex-direction="column" />
					<Box
						box-shadow="3px 3px 7px 1px rgba(0, 0, 0, 0.46)"
						hover-transition="all 0.3s --transitionTimingFunction-easeIn 0.0s"
						hover-transform="scale(1.15)"
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/hoshisama.gif?v=2021-05-15T00:06:51.812Z) 50% 0/cover no-repeat"
						transition="all 0.3s ease 0s"
					/>
					<Text color="--grey" margin="0">
						Danmaku-roguelike
					</Text>
					<Text as="h3" font="--headline3" margin="5px 0 20px 0">
						Hoshisama
					</Text>
					<Text as="p" margin="5px 0 5px 0">
						Bullet hell and roguelike in one game. Help the defender to defeat evil spirits.
						<br />
						<br />
						<Span
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
							user-select="auto"
							pointer-events="auto"
						>
							Combine upgrades and get stronger!
							<br />
							<br />
						</Span>
						Jam version.
					</Text>
					<Box
						text-align="center"
						margin="auto 0px 0px 0px"
						display="block"
						position="static"
						as="div"
					>
						<Link
							background="--color-primary"
							padding="7px 20px 7px 20px"
							text-decoration-line="initial"
							display="inline-block"
							hover-background="--color-darkL1"
							href="https://ternox.itch.io/hoshisama"
							target="_blank"
							margin="3px 3px 3px 3px"
							color="#F7FBFF"
							border-radius="2px"
						>
							Itch.io
						</Link>
					</Box>
				</StackItem>
			</Stack>
		</Section>
		<Section
			sm-padding="40px 0"
			font="--base"
			color="--darkL1"
			background="--color-light"
			padding="0 0"
		>
			<Override slot="SectionContent" max-width="1400px" background="--color-light" />
			<Text
				max-width="100%"
				margin="0 auto 5px auto"
				text-align="center"
				width="100%"
				as="h1"
				font="--headline1"
				md-font="--headline2"
			>
				Games in development
			</Text>
			<Stack margin-top="40px">
				<StackItem width="33%" lg-width="50%" sm-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Box
						padding-bottom="100%"
						background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/kvst.png?v=2021-01-30T23:33:48.906Z) 50% 0/cover no-repeat"
						hover-transform="scale(1.15)"
						hover-transition="all 0.3s --transitionTimingFunction-easeIn 0.0s"
						transition="all 0.3s ease 0s"
						box-shadow="3px 3px 7px 1px rgba(0, 0, 0, 0.46)"
						height="0"
						margin="0 0 20px 0"
					/>
					<Text color="--grey" margin="0">
						Text-based simulator
					</Text>
					<Text as="h3" font="--headline3" margin="5px 0 20px 0">
						STONKS-9800
					</Text>
					<Text as="p" margin="5px 0 5px 0">
						A simulator of a businessman on the Japanese stock market in the 80s and
 90s. Chill, catch a retro vibe, and watch your profits grow in the 
text-based game STONKS-9800.
					</Text>
					<Box
						margin="auto 0px 0px 0px"
						display="block"
						position="static"
						as="div"
						text-align="center"
					>
						<Components.Knopa href="https://store.steampowered.com/app/1539140/STONKS9800/">
							PC (Steam)
						</Components.Knopa>
					</Box>
					{" "}
				</StackItem>
				<StackItem sm-width="100%" sm-margin-top="24px" width="33%" lg-width="50%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Box
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/kvadrr.jpg?v=2021-01-30T23:27:32.406Z) 50% 0/cover no-repeat"
						transition="all 0.3s ease 0s"
						box-shadow="3px 3px 7px 1px rgba(0, 0, 0, 0.46)"
						hover-transition="all 0.3s --transitionTimingFunction-easeIn 0.0s"
						hover-transform="scale(1.15)"
						height="0"
					/>
					<Text color="--grey" margin="0">
						Physic-based tower defence
					</Text>
					<Text as="h3" font="--headline3" margin="5px 0 20px 0">
						BakuMari
					</Text>
					<Text as="p" margin="5px 0 5px 0">
						Crazy game with the building of towers in the most incredible 
situations. Ex-villain Explosion tries to redeem herself and helps a 
mysterious cubie.
					</Text>
					<Box
						margin="auto 0px 0px 0px"
						display="block"
						position="static"
						as="div"
						text-align="center"
					>
						<Link
							background="--color-primary"
							text-decoration-line="initial"
							display="inline-block"
							hover-background="--color-darkL1"
							href="https://store.steampowered.com/app/1470880/BakuMari"
							target="_blank"
							color="#F7FBFF"
							border-radius="2px"
							padding="7px 20px 7px 20px"
							margin="3px 3px 3px 3px"
						>
							PC (Steam)
						</Link>
					</Box>
				</StackItem>
				<StackItem width="33%" lg-width="50%" sm-width="100%" sm-margin-top="24px">
					<Override slot="StackItemContent" flex-direction="column" />
					<Box
						hover-transform="scale(1.15)"
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/kvtai.jpg?v=2021-01-30T23:31:49.627Z) 50% 0/cover no-repeat"
						transition="all 0.3s ease 0s"
						box-shadow="3px 3px 7px 1px rgba(0, 0, 0, 0.46)"
						hover-transition="all 0.3s --transitionTimingFunction-easeIn 0.0s"
					/>
					<Text color="--grey" margin="0">
						Sequel of platformer
					</Text>
					<Text as="h3" font="--headline3" margin="5px 0 20px 0">
						Taimumari 2
					</Text>
					<Text as="p" margin="5px 0 5px 0">
						2D platformer, inspired by the best video games of the last century for 
people who are tired of metroidvanias. Someone has given the academy's 
students mysterious and dangerous powers, and Himari is about to find 
the truth.
					</Text>
					<Box
						margin="auto 0px 0px 0px"
						display="block"
						position="static"
						as="div"
						text-align="center"
					>
						<Link
							target="_blank"
							background="--color-primary"
							display="inline-block"
							href="https://store.steampowered.com/app/1292400/Taimumari_2"
							padding="7px 20px 7px 20px"
							margin="3px 3px 3px 3px"
							color="#F7FBFF"
							text-decoration-line="initial"
							border-radius="2px"
							hover-background="--color-darkL1"
						>
							PC (Steam)
						</Link>
					</Box>
				</StackItem>
			</Stack>
		</Section>
		<Components.Htmlform margin="44px 0px 0px 0px" />
		<Section
			padding="0px 0 70px 0"
			sm-padding="0px 0 60px 0"
			inner-min-width="100%"
			quarkly-title="About-17"
			md-padding="0px 0 40px 0"
		>
			<Override slot="SectionContent" flex-direction="column" align-items="center" />
			<Box
				flex-direction="row"
				flex-wrap="wrap"
				max-width="1440px"
				align-items="flex-start"
				display="flex"
				padding="50px 12px 54px 12px"
				md-padding="50px 12px 12px 12px"
			>
				<Box width="20%" sm-width="100%" sm-margin="0px 0px 16px 0px">
					<Text margin="0px 0px 0px 0px" font="--lead" color="--dark" lg-margin="10px 0px 0px 0px">
						Find me
					</Text>
				</Box>
				<Box width="80%" sm-width="100%">
					<Text
						margin="0px 0px 30px 0px"
						font="normal 400 42px/1.3 --fontFamily-sansHelvetica"
						color="--dark"
						lg-font="normal 400 50px/1.3em &quot;Inter&quot;, sans-serif"
						md-font="--headline3"
						letter-spacing="0.01rem"
					>
						Email me
						<br />
						<Link href="mailto:me@ternoxgames.com?About your game" target="_blank">
							me@ternoxgames.com
						</Link>
						<br />
						<br />
						Follow me on{" "}
						<Link
							href="https://store.steampowered.com/developer/ternox"
							target="_blank"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Steam
						</Link>
						{" "}and{" "}
						<Link
							href="https://mastodon.gamedev.place/@ternoxgames"
							target="_blank"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Mastodon
						</Link>
					</Text>
				</Box>
			</Box>
			<SocialMedia
				facebook="https://www.facebook.com/taimumari"
				twitter="https://twitter.com/taimumari"
				youtube="https://www.youtube.com/channel/UC1rKs1kvj_f1S7lwpHGZ9TQ/"
				telegram="https://t.me/ternoxlair"
				instagram="https://www.instagram.com/taimumari/"
				discord="https://discord.com/invite/cR49SHktjW/"
			>
				<Override
					slot="link"
					border-radius="50%"
					color="--light"
					margin="0 8px"
					background="--color-grey"
					hover-background="--color-greyD1"
				/>
			</SocialMedia>
		</Section>
		<Section padding="140px 0" sm-padding="40px 0" background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/back.jpg?v=2021-04-12T15:34:28.541Z) top/cover">
			<Box
				color="#04080C"
				position="static"
				align-self="flex-end"
				max-width="240px"
				padding="50px 80px 80px 50px"
				background="--color-light"
			>
				<Text
					color="--grey"
					letter-spacing="1px"
					text-transform="uppercase"
					margin="6px 0"
					as="h4"
					font="--base"
				>
					in developing
				</Text>
				<Text as="h2" font="--headline2" margin="0 0 12px 0" width="300px">
					STONKS-9800
				</Text>
				<Text font="--base">
					A simulator of a businessman on the Japanese stock market in the 80s and 90s. Chill, catch a retro vibe, and watch your profits grow in the text-based game STONKS-9800.
				</Text>
				<Link
					text-decoration-line="initial"
					display="block"
					text-align="center"
					background="--color-primary"
					padding="7px 20px 7px 20px"
					margin="3px 3px 3px 3px"
					color="#F7FBFF"
					border-radius="2px"
					hover-background="--color-darkL1"
					href="https://store.steampowered.com/app/1539140/STONKS9800_Stock_Market_Simulator/"
					target="_blank"
				>
					Steam Page
				</Link>
			</Box>
		</Section>
		<Section padding="140px 0" sm-padding="40px 0" background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/twitt.jpg?v=2021-04-11T23:44:26.325Z) top/cover">
			<Box
				max-width="240px"
				padding="50px 80px 80px 50px"
				background="--color-light"
				color="#04080C"
				position="static"
				align-self="flex-start"
			>
				<Text
					as="h4"
					font="--base"
					color="--grey"
					letter-spacing="1px"
					text-transform="uppercase"
					margin="6px 0"
				>
					in developing
				</Text>
				<Text as="h2" font="--headline2" margin="0 0 12px 0" width="280px">
					BakuMari
				</Text>
				<Text font="--base">
					Crazy game with the building of towers in the most incredible situations. Ex-villain Explosion tries to redeem herself and helps a mysterious cubie.{" "}
				</Text>
				<Link
					hover-background="--color-darkL1"
					text-align="center"
					href="https://store.steampowered.com/app/1470880/BakuMari/"
					target="_blank"
					padding="7px 20px 7px 20px"
					margin="3px 3px 3px 3px"
					display="block"
					background="--color-primary"
					color="#F7FBFF"
					text-decoration-line="initial"
					border-radius="2px"
				>
					Steam Page
				</Link>
			</Box>
		</Section>
		<Section padding="140px 0" sm-padding="40px 0" background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/fon.jpg?v=2021-04-12T15:30:59.384Z) top/cover">
			<Box
				align-self="flex-end"
				max-width="240px"
				padding="50px 80px 80px 50px"
				background="--color-light"
				color="#04080C"
				position="static"
			>
				<Text
					letter-spacing="1px"
					text-transform="uppercase"
					margin="6px 0"
					as="h4"
					font="--base"
					color="--grey"
				>
					in developing
				</Text>
				<Text as="h2" font="--headline2" margin="0 0 12px 0" width="280px">
					Taimumari 2
				</Text>
				<Text font="--base">
					Someone has given the academy's students mysterious and dangerous powers, and Himari is about to find the truth.
					<br />
				</Text>
				<Link
					border-radius="2px"
					hover-background="--color-darkL1"
					text-align="center"
					href="https://store.steampowered.com/app/1292400/Taimumari_2/"
					background="--color-primary"
					color="#F7FBFF"
					text-decoration-line="initial"
					target="_blank"
					padding="7px 20px 7px 20px"
					margin="3px 3px 3px 3px"
					display="block"
				>
					Steam Page
				</Link>
			</Box>
		</Section>
		<Section background-color="--dark" text-align="center" padding="32px 0">
			<Image
				src="https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/%D0%A0%D0%B5%D1%81%D1%83%D1%80%D1%81%201@2x.png?v=2022-08-22T18:27:49.967Z"
				display="block"
				width="200px"
				align-self="center"
				srcSet="https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/%D0%A0%D0%B5%D1%81%D1%83%D1%80%D1%81%201@2x.png?v=2022-08-22T18%3A27%3A49.967Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/%D0%A0%D0%B5%D1%81%D1%83%D1%80%D1%81%201@2x.png?v=2022-08-22T18%3A27%3A49.967Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/%D0%A0%D0%B5%D1%81%D1%83%D1%80%D1%81%201@2x.png?v=2022-08-22T18%3A27%3A49.967Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/%D0%A0%D0%B5%D1%81%D1%83%D1%80%D1%81%201@2x.png?v=2022-08-22T18%3A27%3A49.967Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/%D0%A0%D0%B5%D1%81%D1%83%D1%80%D1%81%201@2x.png?v=2022-08-22T18%3A27%3A49.967Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/%D0%A0%D0%B5%D1%81%D1%83%D1%80%D1%81%201@2x.png?v=2022-08-22T18%3A27%3A49.967Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/%D0%A0%D0%B5%D1%81%D1%83%D1%80%D1%81%201@2x.png?v=2022-08-22T18%3A27%3A49.967Z&quality=85&w=3200 3200w"
				sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
			/>
			<Text margin="11px 0px 11px 0px" color="#d7d7d7">
				Nintendo Switch is a trademark of Nintendo.
				<br />
			</Text>
			<Menu
				md-align-items="center"
				display="flex"
				justify-content="center"
				font="--lead"
				font-weight="700"
				margin="-6px 0 16px"
				md-flex-direction="column"
			>
				<Override slot="link" text-decoration="none" color="--light" padding="6px 12px" />
				<Override slot="link-active" color="--primary" />
				<Override slot="item" padding="6px 0px" />
				<Override slot="link-index">
					Main page
				</Override>
				<Override slot="link-policy">
					Private policy
				</Override>
				<Override slot="link-oldone">
					Legacy
				</Override>
			</Menu>
			<Link
				href="mailto:me@ternoxgames.com"
				text-decoration-line="none"
				variant="--base"
				color="--grey"
				hover-color="--primary"
			>
				me@ternoxgames.com
			</Link>
		</Section>
	</Theme>;
});